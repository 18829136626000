
@import "_assets/sass/style.react";

@import "_assets/sass/themes/layout/header/base/light";
@import "_assets/sass/themes/layout/header/menu/light";
@import "_assets/sass/themes/layout/brand/dark";
@import "_assets/sass/themes/layout/aside/dark";
@import "_assets/sass/themes/layout/brand/light.scss";

// Brand themes
.brand-dark {
  @import "_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "_assets/sass/themes/layout/brand/light.scss";
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
